import React from 'react';
import { Button, ButtonProps } from '#/packages/ui';
import FbLoginIcon from '#/packages/ui/src/icons/FbLoginIcon';

export const FacebookButton = (props: ButtonProps & { label?: string }) => (
  <Button color={'secondary'} weight={'outline'} size='xl' wFull flex justify='center' {...props}>
    {props.children || (
      <div className={'text-center'}>
        <span className='flex items-center gap-x-2'>
          <FbLoginIcon className='w-4 h-4' />
          {props.label ?? 'Continue with Facebook'}
        </span>
      </div>
    )}
  </Button>
);
