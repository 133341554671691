/* eslint-disable no-irregular-whitespace */
'use client';

import Link from 'next/link';
import React from 'react';
import { classNames } from '#/packages/ui';
import wavesSvg from '#/src/assets/illustrations/waves3.svg';
import { LogoImg } from '#/src/components/Page/Logo/LogoImg';
import TestimonialCard from './TestimonialCard';
import MabelsLabelsLogo from '../assets/MabelsLabelsLogo';
export interface AuthenticationLayoutProps {
  id?: string;
  header?: React.ReactNode;
  withTerms?: boolean;
  withIllustrations?: boolean;
  children: React.ReactNode;
  leftBlockTitle?: string;
}

export default function AuthenticationLayoutV2(props: AuthenticationLayoutProps) {
  const { withTerms, withIllustrations = false, leftBlockTitle } = props;

  return (
    <div id={props.id} className={'relative flex flex-col min-h-[100vh] bg-secondary-100'}>
      <WavesBackground />
      <div
        className={classNames(
          'relative z-20 flex flex-1 flex-col w-full h-full md:flex-row sm:h-auto sm:justify-center sm:items-center',
          'min-h-full'
        )}
      >
        {withIllustrations && (
          <div className='relative hidden md:flex flex-col md:w-1/2 xl:w-[55%] items-end justify-start min-h-screen p-8 lg:p-16'>
            <div className='flex-col justify-center items-start space-y-8 w-full max-w-[652px]'>
              <LogoImg width={36} />
            </div>

            <div className='flex justify-center items-start flex-grow flex-col max-w-[652px] w-full'>
              <div className='flex flex-col gap-16'>
                <h3 className='text-gray-800 text-4xl font-semibold leading-10'>
                  {leftBlockTitle ?? 'Join 4,000+ teams using Superads to win in paid social!'}
                </h3>

                <TestimonialCard
                  className='mb-auto mt-auto'
                  avatar={'/john-mabels.jpg'}
                  text={
                    <>
                      “Superads is my secret weapon for paid social ad optimization. It{' '}
                      <b>provides so much value</b>!”
                    </>
                  }
                  position={'Joe Machen, Digital Marketing Manager at'}
                  logo={<MabelsLabelsLogo className='w-[114px]' />}
                />
              </div>
            </div>
          </div>
        )}
        <div className='w-full bg-white min-h-screen md:w-1/2 xl:w-[45%] flex items-center'>
          <div className='max-w-[660px] w-full'>
            <div
              className={
                'max-sm:flex max-sm:flex-col max-sm:flex-1 z-20 relative sm:mx-auto sm:w-full transition-all duration-1000'
              }
            >
              <div
                className={
                  'max-sm:flex max-sm:flex-col max-sm:flex-1 max-sm:h-full px-5 md:px-8 py-8 lg:px-20'
                }
              >
                <div className={'mb-4 md:mb-8'}>
                  {typeof props.header === 'string' ? (
                    <h2 className='text-gray-800 text-4xl font-semibold leading-9'>
                      {props.header}
                    </h2>
                  ) : (
                    props.header
                  )}
                </div>
                {props.children}
              </div>
            </div>

            {withTerms ? <Terms /> : null}
          </div>
        </div>
      </div>
    </div>
  );
}

const Terms = () => (
  <p className={'text-center text-xs text-gray-400 m-4 mx-10'}>
    By continuing, you agree to our{' '}
    <Link
      target={'_blank'}
      href={'https://www.superads.ai/terms-of-use'}
      className={'text-gray-500 whitespace-nowrap'}
    >
      Terms of Use
    </Link>{' '}
    and{' '}
    <Link
      target={'_blank'}
      href={'https://www.superads.ai/privacy'}
      className={'text-gray-500 whitespace-nowrap'}
    >
      Privacy Policy
    </Link>
    .
  </p>
);

const WavesBackground = () => (
  <div
    className={`absolute inset-0 max-sm:hidden bg-[#E6D3B01A]`}
    style={{
      WebkitMaskImage: `url(${wavesSvg.src})`,
      maskImage: `url(${wavesSvg.src})`,
      WebkitMaskSize: 'contain',
      maskSize: 'contain',
      WebkitMaskPosition: 'start',
      maskPosition: 'start',
      WebkitMaskRepeat: 'no-repeat',
      maskRepeat: 'no-repeat'
    }}
  />
);
