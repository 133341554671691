'use client';

import React from 'react';
import { Image, classNames } from '#/packages/ui';

interface TestimonialCardProps {
  avatar: string;
  text: JSX.Element;
  position: string;
  logo: JSX.Element;
  className?: string;
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({
  avatar,
  text,
  position,
  logo,
  className
}) => {
  return (
    <div
      className={classNames('flex flex-col gap-8 rounded-2xl bg-white p-8 shadow-lg', className)}
    >
      <div className='flex flex-col lg:flex-row gap-4 lg:gap-8 items-start lg:items-center'>
        <div className='w-24 h-24 rounded-full object-cover'>
          <Image
            src={avatar}
            alt={position}
            fill
            unoptimized
            className='h-24 w-24 rounded-full object-cover'
          />
        </div>
        <p className='text-xl'>{text}</p>
      </div>
      <div className='flex flex-col w-full items-end gap-2'>
        <span className='text-sm text-neutral-600'>{position}</span>

        {logo}
      </div>
    </div>
  );
};

export default TestimonialCard;
