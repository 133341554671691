'use client';

import { SignInOutput, signIn, signInWithRedirect, signOut } from 'aws-amplify/auth';
import React, { ReactNode, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { AuthProvider, useAnalytics } from '#/packages/analytics';
import { FacebookButton } from '#/packages/authentication/components/FacebookButton';
import { GoogleButton } from '#/packages/authentication/components/GoogleButton';
import { Separator } from '#/packages/authentication/components/Separator';
import { SubmitButton } from '#/packages/authentication/components/SubmitButton';
import { Alert, FormField, TextInput } from '#/packages/ui';
import { NavAnchor } from '#/src/components/NavAnchor';

interface LoginFormProps {
  username?: string;
  setUsername: (v: string) => void;
  nextUrl?: string;
  source?: string;
  bottom?: ReactNode;

  onNext(output: SignInOutput): void;
}

export function LoginForm(props: LoginFormProps) {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting }
  } = useForm({ defaultValues: { email: props.username } });

  const { track } = useAnalytics();

  const onSubmit = useCallback(async (data: any) => {
    try {
      const output = await signIn({
        username: data.email,
        password: data.password
      });
      props.setUsername(data.email);
      props.onNext(output);
    } catch (e: any) {
      if (e.name == 'UserAlreadyAuthenticatedException') {
        await signOut();
        onSubmit(data);
      } else {
        console.error(e);
        setError('root.apiError', { message: e.message });
      }
    }
  }, []);

  return (
    <>
      <div className={'space-y-4'}>
        <GoogleButton
          onClick={() => {
            track('auth.login.provider', {
              provider: AuthProvider.GOOGLE
            });
            signInWithRedirect({
              provider: 'Google',
              customState: JSON.stringify({
                nextUrl: props.nextUrl,
                source: props.source,
                provider: AuthProvider.GOOGLE
              })
            });
          }}
        />
        <FacebookButton
          onClick={() => {
            track('auth.login.provider', {
              provider: AuthProvider.FACEBOOK
            });
            signInWithRedirect({
              provider: 'Facebook',
              customState: JSON.stringify({
                nextUrl: props.nextUrl,
                source: props.source,
                provider: AuthProvider.FACEBOOK
              })
            });
          }}
        />
      </div>

      <Separator />

      <form
        data-source={props.source}
        className='flex flex-col gap-y-4 '
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormField id={'email'} label={'Email'}>
          <TextInput
            id='email'
            type='email'
            color={'default'}
            {...register('email', { required: true })}
            autoComplete='email'
            placeholder={'Enter your email address'}
            required
          />
        </FormField>

        <FormField id={'password'} label={'Password'}>
          <TextInput
            id='password'
            type='password'
            color={'default'}
            autoComplete={'current-password'}
            placeholder={'Enter your password'}
            {...register('password', {
              required: true,
              minLength: 8
            })}
          />
          <div className='flex'>
            <NavAnchor size={'xs'} href='/forgot-password'>
              Forgot password?
            </NavAnchor>
          </div>
        </FormField>

        {errors?.root?.apiError ? (
          <Alert>{errors?.root?.apiError?.message?.toString()}</Alert>
        ) : null}

        <div className={'mt-1'}>
          <SubmitButton isLoading={isSubmitting} id={'signin-btn'}>
            {isSubmitting ? 'Logging in...' : 'Log in'}
          </SubmitButton>
        </div>
      </form>

      {props.bottom}
    </>
  );
}
